.header {
    height: 60px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    grid-column: 1 / 3;
    box-shadow: 0px 4px 17px 0px rgba(231, 231, 246, 0.51);

    &__left {
        width: 75px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        background: #FFFFFF;
        box-shadow: 0px 4px 17px 0px rgba(231, 231, 246, 0.51);
    }

    &__logo {
        width: 40px;
        cursor: pointer;
    }

    &__name {
        font-size: 18px;
        margin-left: 25px;
        cursor: pointer;
        user-select: none;
    }
}