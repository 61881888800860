.welcome {
    width: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        width: 774px;
        height: 492px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 12.449px;
        background: rgba(128, 116, 155, 0.12);
    }

    &__text {
        width: 100%;
        box-sizing: border-box;
        padding: 0 90px;
        text-align: center;
        font-size: 18px;

        &-top {
            margin-top: 40px;
            font-size: 20px;
        }

        &-middle {
            margin-top: 30px;
        }

        &-bottom {
            margin-top: 25px;
        }
    }
}