.actions {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 0 15px 0 auto;

    &__item {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F0EEF3;
        border-radius: 10px;
        cursor: pointer;

        &.active {
            background: #DACEEC;
        }
    }
}
