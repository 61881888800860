.sidebar {
    width: 75px;
    height: calc(100% - 60px);
    overflow: auto;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    box-shadow: 0px 4px 17px 0px rgba(231, 231, 246, 0.51);
    gap: 20px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &__item {
        width: 48px;
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(128, 116, 155, 0.12);
        border-radius: 12px;
        cursor: pointer;
    }
}