.messengersSelector {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 5px;
    padding: 5px;
    overflow: auto;

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        background: #FFFFFF;
        position: relative;
        user-select: none;

        &.active {
            background: #E8E2FF;

            .messengersSelector__checkMark {
                display: block;
            }

            .messengersSelector__icon {
                background: rgba(128, 116, 155, 0.12);
            }
        }

        &:not(.empty) {
            cursor: pointer;
        }
    }

    &__checkMark {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &__icon {
        width: 97px;
        height: 97px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        background: rgba(128, 116, 155, 0.12);

        svg {
            width: 50px;
            height: 50px;
        }
    }

    &__name {
        font-size: 18px;
        margin-top: 24px;
    }
}