@font-face {
  font-family: 'Allerta';
  src: url('../public/fonts//Allerta-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Allerta', sans-serif;
  background: #F6F6F8;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.app {
  height: 100vh;
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-template-rows: 60px 1fr;
}
